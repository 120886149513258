import { useMemo } from "react";
import { Button, Table } from "antd";

  const CustomeTable=({headers=[],jsonData=[]})=>{

    const columns=useMemo(()=>{
        let keys=headers||[];
        let firstObj=jsonData[0]
        let result=[]
        for(let str of keys){
            if(firstObj[str].toString()?.includes('http')){
                result.push({
                    title: str.charAt(0)+ str.slice(1,),
                    dataIndex:str,
                    key: str,
                    render: (text) => <a href={text} target="_blank">
                        <Button>View</Button>
                    </a>,
                })
            }else{
                result.push({
                    title: str.charAt(0)+ str.slice(1,),
                    dataIndex:str,
                    key: str,
                })
            }
            
        }
        return result;
    },[jsonData])
    return(
        <>
        <Table scroll={{x:400}} className="w-full" dataSource={jsonData} columns={columns} />
        </>
    )
  }
  
export default CustomeTable;